<template>
  <div class="HomeView">
    <HeaderStep
      :logo="{ ...(flow?.headerLogo), color: '#FFFFFF' }"
      :theme="currentStep.headerTheme"
      :title="$t(`flows.${session.start_type}.steps.home.ctas.${computedCtaType}.title`)"
      :subtitle="$t(`flows.${session.start_type}.steps.home.ctas.${computedCtaType}.subtitle`, {
        app_name: session.appstore?.app_name
      })" />

    <div class="container" style="padding-bottom: 80px;">
      <form class="Field" id="HomeView" @submit.prevent="handleSubmit">
        <InputText class="Field__input" ref="input" v-model.trim="session.email.value" type="email"
          :placeholder="$t('shared.businessEmail')"
          :is-placeholder-persistent="true"
          :is-disabled="isSubmittingEmail"
          :is-invalid="isEmailError" :input-style="{ border: 'none !important' }" />

        <ButtonBase class="Field__button uppercase" type="submit" :is-loading="isSubmittingEmail">
          {{ $t('ctas.getStarted') }}
        </ButtonBase>
      </form>

      <ErrorMessage class="mt-1" v-show="isEmailError || isEmailRateLimitError">
        {{ computedErrorMessage }}
      </ErrorMessage>

      <div class="__legal-sign-in" key="freemium-legal">
        <div
          class="__legal-sign-in">
          <div
            class="__legal"
            v-html="$t('legal.acknowledgePrivacyPolicy')">
          </div>

          <div
            class="__SignIn">
            {{ $t('ctas.alreadyDomoUser') }}
            <a
              class="__legal-link font-semi-bold ml-half"
              href="https://www.domo.com/login">
              {{ $t('ctas.signIn') }}
            </a>
          </div>
        </div>
      </div>

      <LogoBanner class="__LogoBanner" :label="$t('shared.trustedBy')" :breakpoints="[
          { width: 0, columns: 3, rows: 1 },
          { width: 550, columns: 4, rows: 1 },
        ]" :logos="[
          {
            name: 'Zillow',
            maxWidth: 120,
            src: 'https://web-assets.domo.com/miyagi/images/customer/zillow/logo/logo-customer-zillow-black.svg',
          },
          {
            name: 'Univision',
            maxWidth: 60,
            src: 'https://web-assets.domo.com/miyagi/images/customer/univision/logo/logo-customer-univision-black.svg',
          },
          {
            name: 'Ebay',
            maxWidth: 90,
            src: 'https://web-assets.domo.com/miyagi/images/customer/ebay/logo/logo-customer-ebay-black.svg',
          },
          {
            name: 'DHL',
            src: 'https://web-assets.domo.com/miyagi/images/customer/dhl/logo/logo-customer-dhl-black.svg',
          },
        ]" />

      <ListIcons v-if="computedFeatures" class="mt-4" :items="computedFeatures" key="home-features">
      </ListIcons>

      <div class="__fineprint" v-if="session.start_type === 'developer'" key="home-fine-print"
        v-html="$t(`flows.${session.start_type}.steps.home.finePrint`)">
      </div>
    </div>
  </div>
</template>


<script>
import useEmail from '@/use/email';
import ButtonBase from '@/components/ButtonBase.vue';
import ErrorMessage from '@/components/ErrorMessage.vue';
import HeaderStep from '@/components/HeaderStep.vue';
import InputText from '@/components/InputText.vue';
import ListIcons from '@/components/ListIcons.vue';
import LogoBanner from '@/components/LogoBanner.vue';

export default {
  name: 'HomeView',

  components: {
    ButtonBase,
    ErrorMessage,
    HeaderStep,
    InputText,
    ListIcons,
    LogoBanner,
  },

  inject: [
    'session',
    'currentStep',
    'trackEvent',
  ],

  setup() {
    const {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    } = useEmail();

    return {
      isEmailError,
      isEmailRateLimitError,
      isSubmittingEmail,
      submitEmail,
    };
  },

  computed: {
    computedFeatures() {
      const startType = this.session.start_type;
      const methodType = this.session?.appstore?.method;

      if (methodType && methodType !== 'deploy') {
        return this.$i18n.messages[this.$i18n.locale]
          .flows[startType]
          .steps.home
          .ctas[methodType]
          .features;
      }

      return this.$i18n.messages[this.$i18n.locale]
        .flows[startType]
        .steps.home
        .ctas[this.computedCtaType]
        .features;
    },

    computedErrorMessage() {
      return this.isEmailRateLimitError
        ? this.$t('shared.rateLimitError')
        : this.$t('shared.businessEmailError');
    },

    computedCtaType() {
      return this.session?.appstore?.cta_type || 'default';
    },

    computedLoginUrl() {
      const loginPath = this.session?.appstore?.login_path;
      if (loginPath && typeof loginPath === 'object') {
        const queryString = Object.entries(loginPath)
          .map(([key, value]) => `${key}=${encodeURIComponent(value)}`)
          .join('&');
        return `https://www.domo.com/login?${queryString}`;
      }
      return 'https://www.domo.com/login';
    },
  },

  mounted() {
    this.focusEmailInput();
  },

  methods: {
    handleSubmit() {
      this.submitEmail({
        flowName: this.$route.params.flowName,
        session: this.session,
        onSuccess: this.handleSuccess,
        onError: this.handleError,
      });
    },

    handleSuccess() {
      this.trackEvent('submitted email address', this.currentStep, this.session);

      this.$router.push({
        name: 'step',
        params: {
          lang: this.$route.params.lang || '',
          flowName: this.$route.params.flowName,
          stepName: 'confirm',
        },
        query: this.$route.query,
      });
    },

    handleError() {
      this.focusEmailInput();
    },

    focusEmailInput() {
      setTimeout(() => this.$refs.input.select(), 100);
    },
  },
};
</script>


<style lang="scss" scoped>
@import '@/styles/_variables';

.Field {
  display: flex;
  padding: 4px;
  align-items: stretch;
  border-radius: $radius;
  background-color: $white;
  box-shadow: 0 16px 56px -8px rgba($black, 0.5);
  .Field__input {
    flex: 1 1 auto;
  }
  .Field__button {
    flex: 0 0 auto;
  }
}
.__SignIn {
  color: #111111;
  font-size: 14px;
  padding-right: 8px;
  padding-top: 16px;
  text-align: right;
  white-space: nowrap;
}

.__legal-sign-in {
  display: flex;
}
.__legal {
  color: #555555;
  font-size: 12px;
  margin-top: 16px;
  padding-right: 24px;
  text-align: left;
}
.__legal-link {
  color: $link-blue;
  text-decoration: underline;
}

@media only screen and (max-width: 430px) {
  .Field {
    display: block;
    padding: 0px;
    background-color: transparent;
    box-shadow: none;
    .Field__input {
      border-radius: $radius;
      background-color: $white;
      box-shadow: 0 16px 56px -8px rgba($black, 0.5);
      flex: none;
      height: 48px;
    }
    .Field__button {
      display: block;
      flex: none;
      margin-top: 8px;
      width: 100%;
    }
  }
  .__SignIn {
    text-align: center;
  }
  .__legal-sign-in {
    display: block;
  }
  .__legal {
    padding-right: 0;
    text-align: center;
  }
}

.__LogoBanner {
  margin-top: 40px;
  opacity: 0.46;
}

.__fineprint {
  margin-top: 64px;
  color: $mute;
  font-size: 14px;
  text-align: center;
}
</style>
